<template>
  <div v-if="!conferenceIsLoading && conference && conferenceRoom">
    <v-sheet
      dark
      id="conference"
      :style="{ 'background-image': 'url(' + conferenceRoom.backgroundImage + ')' }"
    >
      <v-container class="conference px-0 py-0" fluid>
        <v-row no-gutters>
          <v-col class="hidden-md-and-down conference__logos" cols="12" lg="1">
            <logo :logo="event.logoWeb" />

            <div v-for="partner in mainPartners" :key="partner.id">
              <logo :logo="partner.logoWeb" />
            </div>
          </v-col>

          <v-col class="conference__video" cols="12" lg="8">
            <h2 class="conference__title">{{ conference.name }}</h2>

            <video-switcher
              class="video"
              :user="loggedInUser"
              :video="conference"
              @play="playConference"
              @stop="stopConference"
            />

            <div class="question__wrapper" v-if="question">
              <p v-if="displaySuccess">{{ $t('survey.form.success') }}</p>

              <template>
                <h2>{{ $t('survey.title') }}</h2>
                <div class="question__text">{{ question.question }}</div>

                <div class="question__answer">
                  <v-textarea
                    class="answer__text"
                    v-if="isTextQuestion"
                    outlined
                    no-resize
                    auto-grow
                    rows="1"
                    v-model="questionAnswer"
                    :placeholder="$t('survey.form.question.placeholder')"
                    hide-details
                  />

                  <div v-else class="answer__multiple">
                    <div class="multiple__choices">
                      <v-btn
                        class="ma-1"
                        v-for="(answer, idx) in question.answers"
                        :key="idx"
                        min-width="150"
                        color="primary"
                        :outlined="!isSelected(answer)"
                        :depressed="isSelected(answer)"
                        :loading="isSelected(answer) && answerIsSaving"
                        :disabled="answerIsSaving"
                        @click="toggleAnswerAndSend(answer)"
                        data-test-id="answer-btn"
                      >
                        {{ answer }}
                      </v-btn>
                    </div>
                  </div>

                  <div class="answer__actions" v-if="isTextQuestion">
                    <v-btn
                      color="primary"
                      @click="sendAnswers"
                      :loading="answerIsSaving"
                      :disabled="answerIsSaving"
                    >
                      {{ $t('globals.send') }}
                    </v-btn>
                  </div>
                </div>

                <div v-if="answersHistory && answersHistory.length">
                  <p v-t="'survey.answered'" />

                  <ul>
                    <li v-for="(answer, index) in answersHistory" :key="index">{{ answer }}</li>
                  </ul>
                </div>
              </template>
            </div>

            <div class="conference__speaker hidden-md-and-down">
              <div v-if="hasSpeakerPicture" class="speaker__picture">
                <img class="speaker__picture-wrapper" src="../../assets/images/presenter.png" />
              </div>

              <div class="speaker__content">
                <div v-if="hasSpeakerContent" class="content__title">
                  <v-icon class="content__icon"> mdi-account-plus-outline</v-icon>
                  {{ $t('conference.details.speaker.name') }}
                </div>

                <read-more
                  :less="$t('globals.readLess')"
                  :more="$t('globals.readMore')"
                  class="content__text"
                  :text="conference.description"
                />
              </div>
            </div>

            <v-expansion-panels class="hidden-lg-and-up" flat dark>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >{{ $t('conference.details.title') }}
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="conference__speaker">
                    <div v-if="hasSpeakerPicture" class="speaker__picture">
                      <img
                        class="speaker__picture-wrapper"
                        src="../../assets/images/presenter.png"
                      />
                    </div>

                    <div v-if="hasSpeakerContent" class="content__title">
                      <v-icon class="content__icon"> mdi-account-plus-outline</v-icon>
                      {{ $t('conference.details.speaker.name') }}
                    </div>

                    <div class="content__text" v-html="conference.description" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <documents
              v-if="hasDocuments"
              :documents="conference.documents"
              class="hidden-md-and-down"
            />

            <v-tabs dark v-model="tab" slider-size="0" class="hidden-lg-and-up" centered>
              <v-tab href="#chat" v-if="hasChat">{{ $t('conference.messages.title') }}</v-tab>
              <v-tab href="#documents">{{ $t('conference.documents.title') }}</v-tab>
            </v-tabs>

            <v-tabs-items dark class="tabs hidden-lg-and-up" v-model="tab">
              <v-tab-item value="chat">
                <chat
                  v-if="hasChat"
                  class="chat"
                  :messages="moderatedMessages"
                  :user="loggedInUser"
                  :messages-are-loading="conferenceMessagesAreLoading"
                  @load-more="loadMessages"
                  @send-message="sendMessage"
                  @go-to-profile="goToProfile"
                />
              </v-tab-item>

              <v-tab-item value="documents">
                <documents v-if="hasDocuments" :documents="conference.documents" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-col
            class="conference__chat hidden-md-and-down"
            id="conference-parent-chat"
            cols="12"
            lg="3"
          >
            <v-sheet dark class="conference__chat" v-if="hasChat">
              <chat
                class="chat"
                :messages="moderatedMessages"
                :user="loggedInUser"
                :messages-are-loading="conferenceMessagesAreLoading"
                @load-more="loadMessages"
                @send-message="sendMessage"
                @go-to-profile="goToProfile"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <sponsors :partners="partners" includeOther showPlatinium />
  </div>

  <loading v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { SurveyQuestionType } from '@/models';

import Sponsors from '@/components/sponsors/Sponsors.vue';
import Chat from '@/components/chat/Chat.vue';
import Documents from '@/components/documents/Documents.vue';
import Loading from '@/components/loading/Loading.vue';
import Logo from '@/components/logo/Logo.vue';
import ReadMore from '@/components/read-more/ReadMore.vue';
import VideoSwitcher from '@/components/video-switcher/VideoSwitcher.vue';

import {
  ANSWER_SURVEY_QUESTION,
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_USER_ANSWERS,
  SURVEY_MODULE,
} from '@/stores/umanize-app/actions/survey/survey.actions';
import {
  APP_CONFERENCE_MODULE,
  CONNECT_TO_CONFERENCE_SOCKET,
  DISCONNECT_FROM_CONFERENCE_SOCKET,
  CONFERENCE_CHAT_LOAD_MESSAGES,
  CONFERENCE_SEND_CHATS_MESSAGE,
} from '@/stores/umanize-app/actions/conference/app-conference.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  GET_CONFERENCE,
  GET_CONFERENCE_ROOM,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';

import { getStatSocket, PLAY_CONFERENCE, STOP_CONFERENCE } from '@/socket/stat-namespace';

export default {
  name: 'Conference',
  components: {
    Sponsors,
    Chat,
    Documents,
    Loading,
    Logo,
    ReadMore,
    VideoSwitcher,
  },
  data: () => ({
    tab: null,
    hasSpeakerPicture: false,
    hasSpeakerContent: false,
    questionAnswer: null,
    displaySuccess: false,
    currentQuestion: null,
    answersHistory: [],
  }),
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_CONFERENCE_MODULE, [
      'conference',
      'conferenceRoom',
      'conferenceIsLoading',
      'moderatedMessages',
      'conferenceMessagesAreLoading',
      'nextConference',
    ]),
    ...mapGetters(SURVEY_MODULE, ['question', 'answerIsSaving', 'answerSuccess', 'answersUser']),
    hasDocuments() {
      return !!this.conference.documents?.length;
    },
    hasChat() {
      return !!this.conference.hasChatRoom && !!this.conference.chatId;
    },
    isTextQuestion() {
      return this.question.type === SurveyQuestionType.text;
    },
    partners() {
      return [
        ...(this.event.partners.filter(
          (partner) => partner.type === 'regular' || partner.type === 'platinium',
        ) || []),
        ...(this.conference.partners.filter((partner) => partner.type === 'regular') || []),
      ];
    },
    mainPartners() {
      const partners = this.event.mainPartnerLogo ? [{ logoWeb: this.event.mainPartnerLogo }] : [];
      const conferencePartners =
        this.conference.partners.filter((partner) => partner.type === 'principal') || [];
      return partners.concat(conferencePartners);
    },
  },
  methods: {
    ...mapActions(APP_CONFERENCE_MODULE, [
      GET_CONFERENCE,
      GET_CONFERENCE_ROOM,
      CONNECT_TO_CONFERENCE_SOCKET,
      DISCONNECT_FROM_CONFERENCE_SOCKET,
      CONFERENCE_CHAT_LOAD_MESSAGES,
      CONFERENCE_SEND_CHATS_MESSAGE,
    ]),
    ...mapActions(SURVEY_MODULE, [
      GET_SURVEY_QUESTIONS,
      ANSWER_SURVEY_QUESTION,
      GET_SURVEY_USER_ANSWERS,
    ]),
    isSelected(answer) {
      return answer === this.questionAnswer;
    },

    toggleAnswerAndSend(answer) {
      this.questionAnswer = this.isSelected(answer) ? null : answer;

      this.sendAnswers();
    },
    sendAnswers() {
      if (this.questionAnswer?.trim()) {
        const { eventId, conferenceId } = this.$route.params;

        this[ANSWER_SURVEY_QUESTION]({
          eventId,
          conferenceId,
          questionId: this.question.id,
          answer: this.questionAnswer,
        });
      }
    },
    async initCurrentConference() {
      const { eventId, conferenceId } = this.$route.params;

      await this[GET_CONFERENCE]({
        eventId,
        conferenceId,
      });
      this[CONNECT_TO_CONFERENCE_SOCKET]({ conferenceId, userId: this.loggedInUser.id });
      await this[GET_CONFERENCE_ROOM]({
        eventId,
        conferenceRoomId: this.conference.roomId,
      });
      await this[GET_SURVEY_QUESTIONS]({
        eventId,
        conferenceId,
        withAnswers: false,
      });

      await this[GET_SURVEY_USER_ANSWERS]({
        eventId,
        conferenceId,
        questionId: this.question.id,
      });

      this.answersHistory = this.answersUser ? this.answersUser.map((a) => a.answer) : [];
    },
    async initialize() {
      await this.initCurrentConference();
    },
    sendMessage(message) {
      this[CONFERENCE_SEND_CHATS_MESSAGE]({
        eventId: this.conference.eventId,
        conferenceId: this.conference.id,
        chatId: this.conference.chatId,
        message,
      });
    },
    goToProfile(userId) {
      this.$router.push({
        name: 'EventPublicProfile',
        params: { userId, eventId: this.conference.eventId },
      });
    },
    async loadMessages() {
      if (this.hasChat && !this.conferenceMessagesAreLoading) {
        await this[CONFERENCE_CHAT_LOAD_MESSAGES]({
          eventId: this.conference.eventId,
          conferenceId: this.conference.id,
        });
      }
    },
    playConference() {
      const { eventId, conferenceId } = this.$route.params;
      const timestamp = new Date();
      getStatSocket().emit(PLAY_CONFERENCE, eventId, conferenceId, timestamp);
    },
    stopConference() {
      const { eventId, conferenceId } = this.$route.params;
      const timestamp = new Date();
      getStatSocket().emit(STOP_CONFERENCE, eventId, conferenceId, timestamp);
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this[DISCONNECT_FROM_CONFERENCE_SOCKET]();
  },
  watch: {
    question(updatedQuestion) {
      if (this.currentQuestion?.id !== updatedQuestion?.id) {
        this.questionAnswer = null;
        this.answersHistory = [];
        this.displaySuccess = false;
        this.currentQuestion = updatedQuestion;
      }
    },
    answerSuccess(success) {
      if (success) {
        this.displaySuccess = true;
        this.answersHistory.push(this.questionAnswer);
        this.questionAnswer = null;
      }
    },
    nextConference(newConference) {
      if (
        newConference?.nextConferenceId &&
        newConference.nextConferenceId !== this.$route.params.conferenceId &&
        newConference?.eventId
      ) {
        const params = {
          eventId: newConference.eventId,
          conferenceId: newConference.nextConferenceId,
        };
        this.$router.replace({
          name: 'Conference',
          params,
        });
      }
    },
    $route() {
      this.initialize();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/main';
@import '../../styles/core/variables';

#conference {
  min-height: calc(100vh - 6rem);

  background-image: url('~@assets/backgrounds/conference-room.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.conference {
  height: 100%;

  &__title {
    padding: 1rem 0;

    width: 80%;
    margin: auto;

    color: var(--v-lightFont-base);
  }

  &__speaker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem;
  }
}

.video {
  position: relative;
}

.tabs {
  height: 100vh;
  overflow: scroll;
}

.speaker {
  &__picture {
    flex: 1;

    height: 100%;
  }

  &__picture-wrapper {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
  }

  &__content {
    flex: 8;
    display: flex;
    flex-direction: column;

    color: var(--v-lightFont-base);
  }
}

.content {
  &__title {
    flex: 1;

    font-weight: bold;
  }

  &__icon {
    padding: 0 1rem;
  }

  &__text {
    flex: 3;
    padding: 0 1rem;
    white-space: pre-wrap;
  }
}

.agenda {
  height: 20%;
}

.chat {
  height: 80%;
}

.question {
  &__wrapper {
    padding: 2rem;
  }

  &__text {
    margin-bottom: 0.5rem;
  }

  &__answer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.answer {
  &__text {
    width: 100%;
    font-size: $small-font-size;

    ::v-deep textarea {
      line-height: $line-height-small !important;
    }
  }

  &__multiple {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__actions {
    width: fit-content;
    margin-top: 0.5rem;
  }
}

.multiple {
  &__choices {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

@include breakpoint(small) {
  .conference {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__logos {
      min-height: calc(100vh - 6rem);
    }

    &__chat {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 4rem);
      height: 50vh;
    }

    &__speaker {
      flex-direction: row;
    }
  }

  .agenda {
    flex: 1;
  }

  .chat {
    flex: 3;
    height: auto;
  }

  .video {
    margin: auto;
    width: 60vw;
    min-height: calc(60vw * 9 / 16);
    height: max-content;
  }
}
</style>
